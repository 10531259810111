import { useUser as swUseUser } from "@shopware-pwa/composables-next";



export const useUser = () => {
  const useUserData = swUseUser();
  const issuerGroupId = '018e3162faa971db8d800f236e4c0275';
  const companyGroupId = '018e316329717154911e3945cadd60ec';

  const isIssuer = useUserData?.user?.value?.groupId == issuerGroupId
  const isCompany = useUserData?.user?.value?.groupId == companyGroupId

  return {
    ...useUserData,
    issuerGroupId,
    isIssuer,
    companyGroupId,
    isCompany
  };
};
